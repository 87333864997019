/**
 * Example Stub for creating WCMC Subthemes
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc_labs/themes/wcmc_bootstrap/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc_labs/themes/wcmc_bootstrap/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc_labs/themes/wcmc_bootstrap/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc_labs/themes/wcmc_bootstrap/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc_labs/themes/wcmc_bootstrap/scss/_variables";
@import "../../../../../profiles/wcmc_labs/themes/wcmc_bootstrap/scss/_mixins";

/* =Site-specific styles
----------------------------------------------------------*/

@import "spore_subtheme_styles";

/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/* --- Makes Lab Team 2 columns --- */
@media screen and (min-width: 991px) {
  .member {
    width: 50% !important;
    height: 150px !important;
  }
}

#thumbnail-pager {
  display: none;
}

.wcm-cta__tile {
  border: 1px solid #dddddd !important;
}

.wcm-cta__tile:hover {
  border: 1px solid #dddddd !important;
}

.wcm-cta__tile.external-link:after {
  content: "" !important;
}

.wcm-cta__tile:nth-child(1) {
  border-width: 0 1px 0 0 !important;
  float: left;
}

.wcm-cta__tile:nth-child(1):hover {
  border: 1px solid #dddddd !important;
}

.wcm-cta__tile:nth-child(2) {
  border-width: 0 0 0 0 !important;
}

.wcm-cta__tile:nth-child(2):hover {
  border: 1px solid #dddddd !important;
}

/*.wcm-cta__tile:nth-child(1){
	border-width: 0 1px 0 0 !important;
	float: left;
}*/
/*.wcm-cta__tile:nth-child(2) {
    border-width: 0 0 0 0 !important;
}*/

/* --- Makes Lab Team 2 columns --- */
@media screen and (min-width: 991px) {
	.member {
    	width: 50% !important;
    	height: 150px !important;
	}

}

#thumbnail-pager {
	display:none;
}

.wcm-cta__tile {
    border: 1px solid $wcm-border-gray !important;

    &:hover {
        border: 1px solid $wcm-border-gray !important;
    }
    &.external-link {
        &:after {
            content: "" !important;
        }
    }
    &:nth-child(1){
        border-width: 0 1px 0 0 !important;
        float: left;
        &:hover {
            border: 1px solid $wcm-border-gray !important;
        }
    }
    &:nth-child(2) {
        border-width: 0 0 0 0 !important;
        &:hover {
            border: 1px solid $wcm-border-gray !important;
        }
    }
}
/*.wcm-cta__tile:nth-child(1){
	border-width: 0 1px 0 0 !important;
	float: left;
}*/
/*.wcm-cta__tile:nth-child(2) {
    border-width: 0 0 0 0 !important;
}*/